// import searchForm from "../modules/Forms/searchForm";

/*
        SUMMARY
            1 - SLIDERS
            2 - Modal Video
*/
// try {
//   searchForm();
// } catch (error) {
// }
/*------------/ 1 - SLIDERS /--------------*/
const sliderHome = new Swiper('.heroBannerSlider', {
    loop: true,
    speed: 1000,
    autoplay: {
        delay: 10000,
        disableOnInteraction: false,
      },
    effect: 'fade',
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    on: {
        init: function () {
          initAnimations(); // Call your animation initialization here
        },
        slideChange: function () {
          initAnimations(); // Reinitialize animations on slide change
        }
      }
});
  sliderHome.on('slideChange', function () {
    ScrollTrigger.refresh(true); // Refresh ScrollTrigger instances
  });



  let swiperServices = new Swiper(".swiper-services", {
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true,
    freeMode: false,
    breakpoints: {
      1600: {
        slidesPerView: 2.2,
        spaceBetween: 20,
        centeredSlides: true,
      },
      1400: {
        slidesPerView: 2,
        spaceBetween: 20,
        centeredSlides: false,
      },
      1200: {
        slidesPerView: 2,
        spaceBetween: 20,
        centeredSlides: false,
      },
      1080: {
        slidesPerView: 2,
        spaceBetween: 20,
        centeredSlides: false,
      },
      700: {
        slidesPerView: 2,
        spaceBetween: 20,
        centeredSlides: false,
      },
      500: {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: false,
        autoHeight: true,
      },
    },
  });

  let isLoopInitialized = false;

  // Function to reinitialize the Swiper with loop enabled
  function reinitializeSwiper() {
    if (!isLoopInitialized) {
      isLoopInitialized = true;

      // Get the current slide index
      const currentSlideIndex = swiperServices.activeIndex;

      // Destroy the current swiper instance
      swiperServices.destroy(true, true);

      // Reinitialize the swiper with loop set to true
      swiperServices = new Swiper(".swiper-services", {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: true,
        freeMode: false,
        loop: true, // Set loop to true
        initialSlide: currentSlideIndex, // Set the initial slide to the current slide index
        breakpoints: {
          1600: {
            slidesPerView: 2.2,
            spaceBetween: 20,
            centeredSlides: true,
          },
          1400: {
            slidesPerView: 2,
            spaceBetween: 20,
            centeredSlides: false,
          },
          1200: {
            slidesPerView: 2,
            spaceBetween: 20,
            centeredSlides: false,
          },
          1080: {
            slidesPerView: 2,
            spaceBetween: 20,
            centeredSlides: false,
          },
          700: {
            slidesPerView: 2,
            spaceBetween: 20,
            centeredSlides: false,
          },
          500: {
            slidesPerView: 1,
            spaceBetween: 20,
            centeredSlides: false,
            autoHeight: true,
          },
        },
      });
    }
  }

  // Event listeners for custom buttons
  const leftService = document.querySelector('.controller-service .left');
  const rightService = document.querySelector('.controller-service .right');

  leftService.addEventListener('click', () => {
    reinitializeSwiper(); // Check if loop needs to be enabled
    swiperServices.slidePrev();
  });

  rightService.addEventListener('click', () => {
    reinitializeSwiper(); // Check if loop needs to be enabled
    swiperServices.slideNext();
  });

  // Add event listener for slide change
  swiperServices.on('slideChange', reinitializeSwiper);

  window.addEventListener('load', () => {
    const serviceDepo = document.querySelectorAll('.swiper-services .swiper-slide');
    let maxHeight3 = 0;

    serviceDepo.forEach(card => {
        if (card.offsetHeight > maxHeight3) {
        maxHeight3 = card.offsetHeight;
        }
    });

    // Set all cards to the height of the tallest card
    serviceDepo.forEach(card => {
        card.style.height = `${maxHeight3}px`;
    });
})




  const swiperDepo = new Swiper(".swiper-depo", {
    slidesPerView: 1.2,
    spaceBetween: 13,
    freeMode: false,
    loop: true,
    breakpoints: {
      991: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      600: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      }

    }
  });

  const depoLeft = document.querySelector('.depo-left');
  const depoRight = document.querySelector('.depo-right');

  depoLeft.addEventListener('click', () => {
    swiperDepo.slidePrev();
  });

  depoRight.addEventListener('click', () => {
    swiperDepo.slideNext();
  });



window.addEventListener('load', () => {
    const depoCards = document.querySelectorAll('.depo-card');
    let maxHeight2 = 0;

    depoCards.forEach(card => {
        if (card.offsetHeight > maxHeight2) {
        maxHeight2 = card.offsetHeight;
        }
    });

    // Set all cards to the height of the tallest card
    depoCards.forEach(card => {
        card.style.height = `${maxHeight2}px`;
    });
})

/*------------/ 2 - MODAL VIDEO /--------------*/
const glightbox = GLightbox();


/*------------/ 3 - Animation sections /--------------*/

// $(function () { // wait for document ready
//     // init
//     var controller = new ScrollMagic.Controller({
//         globalSceneOptions: {
//             triggerHook: 'onLeave',
//             duration: "200%" // this works just fine with duration 0 as well
//             // However with large numbers (>20) of pinned sections display errors can occur so every section should be unpinned once it's covered by the next section.
//             // Normally 100% would work for this, but here 200% is used, as Panel 3 is shown for more than 100% of scrollheight due to the pause.
//         }
//     });
//     console.log('asdfasdas')

//     // get all slides
//     var slides = document.querySelectorAll(".panel");

//     // create scene for every slide
//     for (var i=0; i<slides.length; i++) {
//         new ScrollMagic.Scene({
//                 triggerElement: slides[i]
//             })
//             .setPin(slides[i], {pushFollowers: false})
//             // .addIndicators() // add indicators (requires plugin)
//             .addTo(controller);
//     }
// });

/*------------/ 4 - Scroll down btn /--------------*/
document.querySelector('.scroll-down').addEventListener('click', function() {
    // Calculate 100vh in pixels
    const viewportHeight = window.innerHeight;

    // Scroll down by 100vh
    window.scrollBy({
      top: viewportHeight, // 100vh
      behavior: 'smooth' // Optional: for smooth scrolling
    });
  });


/* -------/ 5-  SET CARDS SWIPER SERVICE SAME SIZE OF HEIGHT /------- */

// const cards = document.querySelectorAll('.services-slider .swiper-slide');
// let maxHeight = 0;

// // Find the tallest card
// cards.forEach(card => {
//   if (card.offsetHeight > maxHeight) {
//     maxHeight = card.offsetHeight;
//   }
// });

// // Set all cards to the height of the tallest card
// cards.forEach(card => {
//   card.style.height = `${maxHeight}px`;
// });

function initAnimations() {
    let revealText = document.querySelectorAll(".reveal-text");
    let results = Splitting({ target: revealText, by: "lines" });

    results.forEach((splitResult) => {
      const wrappedLines = splitResult.lines
        .map(
          (wordsArr) => `
          <span class="line"><div class="words">
            ${wordsArr
              .map(
                (word) => `${word.outerHTML}<span class="whitespace">
           </span>`
              )
              .join("")}
          </div></span>`
        )
        .join("");
      splitResult.el.innerHTML = wrappedLines;
    });

    gsap.registerPlugin(ScrollTrigger);
    revealText.forEach((element) => {
      const lines = element.querySelectorAll(".line .words");

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          // Ajustar toggleActions para "play none none none" para que a animação execute apenas uma vez
          toggleActions: "play none none none",
          start: "top bottom", // Inicia a animação quando o topo do gatilho atinge o fundo da janela
          once: true // Garante que a animação ocorra somente uma vez
        }
      });
      tl.set(lines, { autoAlpha: 1 }); // Aplica visibilidade às linhas
      tl.from(lines, 1, {
        yPercent: 100,
        ease: Power3.out,
        stagger: 0.25,
        delay: 0.2
      });
    });
}

